// --- Dependencies
import * as React from 'react'

// --- Components
import Layout from 'components/Layout'
import Section from 'components/Section'

/**
 * Component
 */

const IndexPage = () => (
  <Layout
    title="Leiðbeiningar"
    description="Boxið"
  >
    <div className="center mw9 ph4 mt5">
      <p className="lh-copy f3 tc mw7 center mb5">
        <a href="https://boxid.is" className="spree-blue fw6">
          Boxið
        </a>
        &nbsp;er fullkomið vefverslunarkerfi og app sem tengist þínu sölukerfi.
      </p>

      <div className="mw8 center">
        <div className="flex flex-column flex-wrap flex-row-ns mv4 w-100">
          <Section path="/user" title="Notendaleiðbeiningar" className="w-50-ns">
          Þessar leiðbeiningar eru ætlaðar stjórnendum netverslana sem keyra á Boxinu. Allt sem þú þarft að vita til að stýra og breyta netversluninni þinni getur þú fundið á þessari síðu.
          </Section>

          <Section path="/api" title="API Guides" className="w-50-ns">
            The REST API is designed to give developers a convenient way to
            access data contained within Boxið. With a standard read/write
            interface to store data, it is now very simple to write third party
            applications (JavaScript/Mobile/other technologies) that can talk to
            your Boxið store.
          </Section>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
